<template>
  <div class="h-full w-full flex flex-col justify-start items-center">
      <Wrapper class="max-w-lg">
        <div class="w-full max-w-xs mx-auto">
          <div class="w-full my-5">
            <h2 class="mb-3 text-2xl md:text-3xl text-blue-dark"><b>Ingrese el código de verificación</b></h2>
            <p class="text-sm text-gray-500">Hemos enviado un código de verificación de 4 dígitos al correo electrónico suministrado en el registro</p>
          </div>
          <form class="w-4/5 mx-auto mt-7 grid grid-cols-4 gap-4 justify-center items-center">
            <input type="number" min="0" max="9" class="rounded-md border-gray-300 text-center flex justify-center items-center" @keypress="codeInputHandler($event, 'one')" @keyup.delete="codeInputHandler($event, 'one')"  :value="code.one" >
            <input type="number" min="0" max="9" class="rounded-md border-gray-300 text-center flex justify-center items-center" @keypress="codeInputHandler($event, 'two')" @keyup.delete="codeInputHandler($event, 'two')" :value="code.two">
            <input type="number" min="0" max="9" class="rounded-md border-gray-300 text-center flex justify-center items-center" @keypress="codeInputHandler($event, 'three')" @keyup.delete="codeInputHandler($event, 'three')" :value="code.three">
            <input type="number" min="0" max="9" class="rounded-md border-gray-300 text-center flex justify-center items-center" @keypress="codeInputHandler($event, 'four')" @keyup.delete="codeInputHandler($event, 'four')" :value="code.four">
          </form>
          <div class="w-full my-4 mx-auto">
            <p v-if="feedback" class="mb-3 text-sm text-center text-red-500">{{feedback}}</p>
            <a-button type="primary" :block="true" shape="round" @click.native="verifyCode" :loading="loading" :disabled="disableButton || invalidCode">
              INGRESAR CÓDIGO
            </a-button>
          </div>
          <p class="mt-3 text-xs">¿No recibió el código?<br> <b :class="{'text-blue cursor-pointer':!disableResend, 'text-gray-500 pointer-events-none': disableResend }" @click="resendCode">Enviar código nuevamente <span class="text-gray-500">{{counter}}</span></b></p>
        </div>
      </Wrapper>
  </div>
</template>

<script>
export default {
  props: ['email', 'password'],
  mounted(){
    let firstInput = document.querySelectorAll('form input')[0];
    firstInput.focus();
  },
  data(){
    return {
      code: {
        one: '',
        two: '',
        three: '',
        four: ''
      },
      counter: 0,
      disableResend: '',
      disableButton: false,
      feedback: '',
      loading: false
    }
  },
  methods: {
    async verifyCode() {
      this.disableButton = true;
      let form = {
        email: this.$store.state.session.user.email,
        confirmation_code: this.code.one+''+this.code.two+''+this.code.three+''+this.code.four
      }

      this.loading = true;
      let {data, error} = await this.$api.verifyCode(form);
      this.loading = false;

      if (error) {
        this.disableButton = false;
        if(error?.response?.status == 400){
          this.feedback = "El código es incorrecto";
        } else {
          this.feedback = error ? error?.error : "Hubo un error validando el código";
        }
      }

      if (data) {
        this.feedback = "";
          this.$store.dispatch('session/loadUser').then(()=>{
            this.$router.push('/');
          });
      }
    },
    async resendCode() {
      let form = {email: this.$store.state.session.user.email?.toLowerCase()};
      this.timer();
      this.loading = true;
      this.disableButton = true;
      let {data, error} = await this.$api.resendCode(form);
      this.loading = false;
      this.disableButton = false;

      if (error) {
        this.feedback = error ? error?.error : "Error enviando la solicitud";
      }

      if (data) {
        // Nothing
      }
    },
    codeInputHandler(event, input){
      let actualInput = event.target;
      let nextSibling = event.target.nextSibling;
      let previousSibling = event.target.previousSibling;

      if(event.key == "Backspace" || event.key == "Delete"){
        this.code[input] = '';
        this.toggleInput(actualInput, previousSibling);
      } else {
        let number = parseInt(event.key) >= 0 ? parseInt(event.key) : '';
        this.code[input] = number;
        if(number === ''){return;}
        this.toggleInput(actualInput, nextSibling)
      }
    },
    toggleInput(actual, next){
      actual.blur();
      if(next){
        setTimeout(()=>{
          next.focus();
        }, 100)
      }
    },
    timer(){
      this.disableResend = true;
      this.counter = 60;
      let interval = setInterval(()=>{
        if(this.counter < 1){
          clearInterval(interval)
          this.counter = 0;
          this.disableResend = false;
        } else {
          this.counter = this.counter - 1;  
        }  
      },1000)
    }
  },
  computed: {
    invalidCode: function(){
      let disable = false;
      Object.keys(this.code).forEach((key)=>{
        if(this.code[key] === ''){
          disable = true
          return;
        }
      });
      return disable;
    }
  }
}
</script>

<style>

</style>